import React from "react"
import Layout from "../components/Layout" 
import { graphql, Link } from "gatsby"
import img from "../images/apropos/apropos_main.jpg"

import portrait_baptiste from "../images/apropos/team/Baptiste.jpg"
import portrait_blandine from "../images/apropos/team/Blandine.jpg"
import portrait_camille from "../images/apropos/team/Camille.jpg"
import portrait_manon from "../images/apropos/team/Manon.jpg"
import portrait_faiz from "../images/apropos/team/Faiz.jpg"
import portrait_lauryn from "../images/apropos/team/Lauryn.jpg"
import portrait_matthieu from "../images/apropos/team/Matthieu.jpg"
import portrait_morgan from "../images/apropos/team/Morgan.jpg"
import portrait_robin from "../images/apropos/team/Robin.jpg"
import portrait_samantha from "../images/apropos/team/Samantha.jpg"
import portrait_sylvain from "../images/apropos/team/Sylvain.jpg"
import portrait_etienne from "../images/apropos/team/Etienne.jpg"
import portrait_alois from "../images/apropos/team/Alois.jpg"

const AboutPage = ({ data }) => {

  return (
    <>
      <Layout>

        <div className="page-wrapper pro">
          
          <div className="main-image">
            <img src={img}/>
          </div>
          
          <h1>PORTAIL PROFESSIONNEL</h1>


          <div className="list-agenda-header">
            <h2>Le roi est mort</h2>
            <h3>Création</h3>
          </div>

          <div className="list-agenda">
            <div className="list-agenda-item">
                <h4>02/12/2023</h4>
                <h4>Captation</h4>
                <h5>40 minutes</h5>
                <h5>7 interprètes</h5>
                <a href="https://youtu.be/b6JBsWQnp5o" target="_blank" rel="noreferrer">Visionner</a>
            </div>
            <div className="list-agenda-item">
                <h4>08/08/2023</h4>
                <h4>Captation</h4>
                <h5>10 minutes</h5>
                <h5>12 interprètes</h5>
                <a href="https://youtu.be/_Ur-fR-3feo" target="_blank" rel="noreferrer">Visionner</a>
            </div>
          </div>

         <br/>
         <br/>

          <h1>CRÉDITS</h1>

          <p>Soutiens: Le POC théatre d'Alfortville, La Biennale internationale des arts numérique de la Région Île-de-France NEMO</p>
          <p>Photographie: Eyla AMGHAR Matthias DAMAY, Aloïs LECLET</p>
          <p>Prise de vue: Eyla AMGHAR</p>
        </div>
      </Layout>
    </>
  )
}

export default AboutPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
